import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import News from '../components/homeSections/NewsTeaser'
import StayUpToDate from '../components/homeSections/StayUpToDate'
import BigFeature from '../components/homeSections/BigFeature'
import { locales } from '../../i18n'
import { getCookie } from '../utils/cookies'
import Order from '../components/homeSections/Order'
import Accolades from '../components/homeSections/Accolades/Accolades'
import Features from '../components/homeSections/Features'
import useBigFeatures from '../components/homeSections/useBigFeatures'
import GallerySection from '../components/homeSections/Media/GallerySection'
import FeaturedGallerySection from '../components/homeSections/Media/FeaturedGallerySection'

export const pageQuery = graphql`
  query ($locale: String) {
    strapiGlobal {
      siteName
      siteDescription
    }
    allStrapiArticle(
      limit: 3
      filter: { locale: { eq: $locale }, publishedAt: { ne: null } }
      sort: { fields: articleDate, order: DESC }
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`

const Main = ({ allStrapiArticle }) => {
  const [about, choices, comic, dramatic] = useBigFeatures()
  return (
    <main>
      <BigFeature feature={about} withGradient />
      <Features />
      <Accolades />
      <BigFeature feature={choices} />
      <FeaturedGallerySection />
      <GallerySection />
      <Order />
      <BigFeature feature={comic} />
      <News articles={allStrapiArticle.nodes} />
      <BigFeature feature={dramatic} />
      <StayUpToDate />
    </main>
  )
}

function IndexPage({ pageContext, data }) {
  const { strapiGlobal, allStrapiArticle } = data

  useEffect(() => {
    // TODO: Pending bug, this logic should be on every page.. use layout? watchout coming soon has own layout and article too!
    // auto redirect to user agent locale unless lang cookie is present
    const langCookie = getCookie('pref-lang')

    // if there's no navigator we can't do this... just default to en
    const uaLocale = navigator?.language?.split('-')[0]
    const loc = locales[uaLocale]?.path ?? locales.en.path
    if (!langCookie && loc !== locales.en.path) {
      // { replace: true } is needed because we are auto redirecting, don't use it to navigate otherwise
      navigate(`/${loc}/`, { replace: true })
    } else if (!!langCookie && langCookie !== locales.en.path) {
      // redirect to lang cookie value unless it's en
      navigate(`/${langCookie}/`, { replace: true })
    }
  }, [])

  return (
    <Layout pageContext={pageContext} parallax>
      <Seo seo={{ metaTitle: null }} />
      <Hero
        title={strapiGlobal.siteName}
        description={strapiGlobal.siteDescription}
      />
      <Main allStrapiArticle={allStrapiArticle} />
    </Layout>
  )
}

export default IndexPage
