import { find, propEq } from 'ramda'
import useCampaigns from './useCampaigns'

const useCampaignPicture = pictureName => {
  const campaigns = useCampaigns()

  return campaigns.reduce((pictureSoFar, { media }) => {
    const campaignMedia = find(propEq('name', pictureName), media)
    return campaignMedia?.picture || pictureSoFar
  }, null)
}

export default useCampaignPicture
