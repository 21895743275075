import React from 'react'
import { LargeCloseButton } from './CloseButton'

export default function OverlayWithClose(props, contentElement) {
  return (
    <div {...props}>
      {contentElement}
      <LargeCloseButton className="absolute right-8 -top-2 scale-[0.6] cursor-pointer fill-primary transition-colors hover:fill-white 2xl:right-8 2xl:-top-1" />
    </div>
  )
}
