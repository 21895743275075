import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import LocaleContext from '../../contexts/localContext'
import { allNodesForLocale } from '../../utils/gatsby-strapi-helpers'

const useCampaigns = () => {
  const {
    allStrapiCampaign: { nodes },
  } = useStaticQuery(graphql`
    query CampaignsQuery {
      allStrapiCampaign(filter: { active: { eq: true } }) {
        nodes {
          locale
          media {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)
  return allNodesForLocale(locale)(nodes)
}

export default useCampaigns
