import React from 'react'

import DesktopGallery from './Desktop/DesktopGallery'
import MobileGallery from './MobileGallery'
import { SpecialHeader2 } from '../../shared/Text'
import useMessages from '../../../hooks/useMessages'
import useMedia from '../../../hooks/useMedia'

export default function Media({ items, titleKey }) {
  const msg = useMessages()
  const isMediumRes = useMedia('(min-width: 1024px)')

  const Gallery = isMediumRes ? DesktopGallery : MobileGallery
  return (
    <div id="media" className="py-16 lg:px-10 lg:py-32">
      <div className="flex w-full flex-col flex-wrap justify-between gap-7 2xl:gap-12">
        <SpecialHeader2>{msg(titleKey)}</SpecialHeader2>
        <Gallery items={items} />
      </div>
    </div>
  )
}
