import React from 'react'

const useModal = () => {
  const [isModalOpen, setIsOpen] = React.useState(false)
  const closeModal = React.useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])
  const openModal = React.useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  return { isModalOpen, closeModal, openModal }
}

export default useModal
