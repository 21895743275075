import React from 'react'
import Modal from 'react-modal'
import { prop, sortBy } from 'ramda'

import { MainButton } from '../../shared/Buttons'
import { Caption } from '../../shared/Text'
import Strip from './ShipStrip'
import { CloseButton } from '../../shared/CloseButton'
import useMessages from '../../../hooks/useMessages'

export default function RetailersModal({ retailers, isModalOpen, closeModal }) {
  const msg = useMessages()
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Retailers list"
      className="absolute inset-x-4 inset-y-5 m-auto  overflow-auto rounded bg-background px-2.5 text-slate-50 outline-none xs:inset-x-8 md:inset-x-20 lg:inset-x-[20%] 2xl:inset-x-[30%]"
      overlayClassName="fixed inset-0 z-40 bg-black bg-opacity-30"
    >
      <div className="flex h-full w-full flex-col items-center gap-10">
        <div className="absolute w-full">
          <CloseButton
            btnClassName="relative top-2 left-[88%] md:left-[93%] lg:left-[95%] z-40"
            className="top-2 fill-white"
            onClick={closeModal}
          />
          <Strip className="relative -top-7 !h-[4.2rem] w-full !bg-shuttlesModal lg:!h-[6rem]" />
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center gap-10">
          <div className="flex flex-col items-center justify-center gap-6 text-center">
            <Caption>{msg('store.modal.desc')}</Caption>
          </div>
          {/* lg:[&>*:last-child:nth-child(odd)]:col-span-2 --means-> if lg viewport AND odd N of items make the last one span the 2 cols */}
          <div className="grid grid-cols-1 justify-items-center gap-4 lg:grid-cols-2 lg:[&>*:last-child:nth-child(odd)]:col-span-2">
            {sortBy(prop('priority'), retailers).map(
              ({ retailerLabel, retailerSlug, url }) => (
                <MainButton
                  // TODO: maybe default to double height button?
                  className="flex !h-full w-[15rem] items-center justify-center"
                  key={retailerSlug}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                >
                  {retailerLabel}
                </MainButton>
              )
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
