import React, { useCallback, useMemo, useState } from 'react'
import {
  append,
  flip,
  identity,
  includes,
  keys,
  lensProp,
  map,
  mapObjIndexed,
  over,
  pipe,
  prop,
  propEq,
  propSatisfies,
  uniq,
} from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Select from '../../shared/Select'
import useStorePlatforms from '../../../hooks/useStorePlatforms'
import PhysicalPlatformButton from './PhysicalPlatformButton'
import { allNodesForLocale } from '../../../utils/gatsby-strapi-helpers'
import LocaleContext from '../../../contexts/localContext'
import { Media } from './constants'
import useMessages from '../../../hooks/useMessages'

const included = flip(includes)

const onlyAvailable = (regions, retailers) => {
  const available = {}
  retailers.forEach(({ region, versions }) => {
    versions.forEach(version => {
      // eslint-disable-next-line camelcase
      const { strapi_json_value } = version.storePlatform.media
      // eslint-disable-next-line camelcase
      if (strapi_json_value.includes(Media.Physical)) {
        available[region.identifier] = [
          ...(available[region.identifier] ?? []),
          version.storePlatform.slug,
        ]
      }
    })
  })
  const platformsByRegion = mapObjIndexed(uniq, available)
  const availableRegions = regions.filter(
    propSatisfies(included(keys(available)), 'identifier')
  )
  return { availableRegions, platformsByRegion }
}

export default function PhysicalStore() {
  const {
    allStrapiStoreRetailer: { nodes: retailers },
    allStrapiStoreRegion: { nodes: regionNodes },
  } = useStaticQuery(graphql`
    query PhysicalStoreRetailers {
      allStrapiStoreRegion {
        nodes {
          identifier
          locale
          label
          slug
        }
      }
      allStrapiStoreRetailer {
        nodes {
          slug
          priority
          label
          region {
            identifier
            slug
            label
            locale
          }
          versions {
            url
            storePlatform {
              # only this fields are used at this point
              slug
              sortOrder
              media {
                strapi_json_value
              }
            }
          }
        }
      }
    }
  `)

  const { locale } = React.useContext(LocaleContext)
  const regions = allNodesForLocale(locale)(regionNodes)
  const allPlatforms = useStorePlatforms()
  const [selectedRegion, setRegion] = useState()
  const regionSelected = useCallback(
    region => setRegion(region.identifier),
    [setRegion]
  )
  const { availableRegions, platformsByRegion } = useMemo(
    () => onlyAvailable(regions, retailers),
    [regions, retailers]
  )

  // on the cms each retailer has a version for a platform
  // now we need the info the other way around
  // we need for each platform, all retailers with a version for that platform
  // so we end up with an obj with a key for each platform and it's value is an array of retailers
  const retailersByPlatform = useMemo(() => {
    const regionPlatforms = platformsByRegion[selectedRegion] ?? []
    const reduceInit = regionPlatforms.reduce(
      (acc, cur) => ({ ...acc, [cur]: [] }),
      {}
    )

    return retailers
      .filter(
        ({ region: { identifier: regionIdentifier } }) =>
          regionIdentifier === selectedRegion
      )
      .reduce((acc, retailer) => {
        const {
          slug: retailerSlug,
          label: retailerLabel,
          priority,
          versions,
        } = retailer

        const ops = versions.map(({ storePlatform, url }) => {
          const { media, slug: platformSlug } = storePlatform
          if (!media.strapi_json_value.includes(Media.Physical)) {
            return identity
          }
          // creates operations to perform on the object that has a retailers empty array for each platform
          return over(
            lensProp(platformSlug),
            append({
              retailerSlug,
              retailerLabel,
              url,
              priority,
            })
          )
        })
        return pipe(...ops)(acc)
      }, reduceInit)
  }, [retailers, allPlatforms, selectedRegion])

  const msg = useMessages()

  return (
    <>
      <Select
        options={availableRegions}
        labelFn={prop('label')}
        onSelected={regionSelected}
        defaultValue={msg('store.chooseRegion')}
      />

      {/* Platforms */}
      <div className="flex flex-col gap-8 lg:flex-row">
        {map(
          ({ slug: platformSlug }) =>
            retailersByPlatform[platformSlug] ? (
              <PhysicalPlatformButton
                key={platformSlug}
                platform={allPlatforms.find(propEq('slug', platformSlug))}
                retailers={retailersByPlatform[platformSlug]}
              />
            ) : null,
          allPlatforms
        )}
      </div>
    </>
  )
}
