import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { getImage } from '../../utils/gatsby-strapi-helpers'

import { SpecialHeader2, Body, SpecialHeader1 } from '../shared/Text'
import { Desktop, Mobile } from '../viewport/breakpoints'

import PhysicalStore from './Store/PhysicalStore'
import DigitalStore from './Store/DigitalStore'
import useMessages from '../../hooks/useMessages'
import { Media } from './Store/constants'
import useCampaignPicture from '../../hooks/campaigns/useCampaignPicture'

const Physical = `${Media.Physical[0].toUpperCase()}${Media.Physical.slice(1)}`
const Digital = `${Media.Digital[0].toUpperCase()}${Media.Digital.slice(1)}`

const baseMediaClasses = 'w-full rounded box-border'

const MediaButton = ({ media, screen, children }) => {
  const msg = useMessages()

  const ScreenBreakpoint = screen === 'Desktop' ? Desktop : Mobile
  return (
    <ScreenBreakpoint>
      <div className="relative">
        {children}
        <SpecialHeader1
          className="
        absolute
        top-0
        right-0
        -mr-2
        flex
        h-full
        w-1/2
        items-center
        justify-center

        lg:top-auto
        lg:bottom-2
        lg:right-auto
        lg:block
        lg:h-auto
        lg:w-full
        xl:bottom-9
        2xl:bottom-1"
        >
          <span className="max-:w-24 w-[40%] text-center max-md:text-xs lg:w-full">
            {msg(`store.${media}`)}
          </span>
        </SpecialHeader1>
      </div>
    </ScreenBreakpoint>
  )
}

const classesFor = (aMedia, selectedMedia) =>
  classNames(baseMediaClasses, {
    'border-primary border-2':
      aMedia.toLowerCase() === selectedMedia.toLowerCase(),
  })

const DigitalMobileImage = ({ className }) => {
  const campaignPicture = useCampaignPicture('digitalStore_mobile')

  return campaignPicture ? (
    <GatsbyImage
      image={getImage(campaignPicture)}
      className={className}
      objectFit="cover"
      alt="Digital version"
    />
  ) : (
    <StaticImage
      src="../../../static/pics/Order/Digital_Mobile.png"
      className={className}
      objectFit="cover"
      alt="Digital version"
    />
  )
}

const DigitalDesktopImage = ({ className }) => {
  const campaignPicture = useCampaignPicture('digitalStore_desktop')

  return campaignPicture ? (
    <GatsbyImage
      image={getImage(campaignPicture)}
      className={className}
      objectFit="cover"
      alt="Physical version"
    />
  ) : (
    <StaticImage
      src="../../../static/pics/Order/Digital_Desktop.png"
      className={className}
      objectFit="cover"
      alt="Physical version"
    />
  )
}

function MediaSelector({ media, setMedia }) {
  const setPhysical = useCallback(() => setMedia(Media.Physical), [setMedia])
  const setDigital = useCallback(() => setMedia(Media.Digital), [setMedia])

  return (
    <div className="flex flex-col gap-4 lg:flex-row lg:gap-10">
      <button type="button" onClick={setPhysical}>
        <MediaButton media={Physical} screen="Mobile">
          <StaticImage
            src="../../../static/pics/Order/Physical_Mobile.png"
            className={classesFor(Physical, media)}
            objectFit="cover"
            alt="Digital version"
          />
        </MediaButton>
        <MediaButton media={Physical} screen="Desktop">
          <StaticImage
            src="../../../static/pics/Order/Physical_Desktop.png"
            className={classesFor(Physical, media)}
            objectFit="cover"
            alt="Physical version"
          />
        </MediaButton>
      </button>
      <button type="button" onClick={setDigital}>
        <MediaButton media={Digital} screen="Mobile">
          <DigitalMobileImage className={classesFor(Digital, media)} />
        </MediaButton>
        <MediaButton media={Digital} screen="Desktop">
          <DigitalDesktopImage className={classesFor(Digital, media)} />
        </MediaButton>
      </button>
    </div>
  )
}

const Order = () => {
  const [media, setMedia] = useState(Media.Physical)
  const msg = useMessages()
  return (
    <div className="w-screen py-16 lg:py-36">
      <div
        id="get-game"
        className="container flex flex-col place-items-center gap-8"
      >
        <div className="relative flex flex-col gap-4 text-center lg:gap-8">
          <SpecialHeader2>{msg('store.title')}</SpecialHeader2>
          <Body>{msg('store.desc')}</Body>
        </div>

        <MediaSelector media={media} setMedia={setMedia} />

        {/* physical store */}
        {media === Media.Physical && <PhysicalStore />}

        {/* digital store */}
        {media !== Media.Physical && <DigitalStore />}
      </div>
    </div>
  )
}

export default Order
