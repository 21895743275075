import React, { useCallback, useRef } from 'react'
import { prop, sortBy } from 'ramda'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import classNames from 'classnames'

import CaretButton from '../../shared/CaretButton'
import { getAltText, getImage } from '../../../utils/gatsby-strapi-helpers'
import PlayButton from '../../shared/PlayButton'
import useModal from '../../../hooks/useModal'
import WatchMoreModal from '../../video/WatchMoreModal'

function GalleryItem({ item, idx }) {
  const isVideo = item.youtubeVideoId
  const { isModalOpen, closeModal, openModal } = useModal()
  const openModalIfVideo = useCallback(() => {
    if (isVideo) {
      openModal()
    }
  }, [isVideo])

  return (
    <div
      className={classNames('container w-full', {
        relative: isVideo,
      })}
    >
      <GatsbyImage
        // className="rounded-4"
        // TODO: check why the classname is not honored!
        style={{ borderRadius: '0.25rem' }}
        image={getImage(item.picture)}
        alt={getAltText(item.picture)}
        objectFit="contain"
        loading={idx === 0 || idx === 1 ? 'eager' : 'lazy'}
      />
      <PlayButton
        onClick={openModalIfVideo}
        btnClassName={classNames(
          { hidden: !isVideo },
          'absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 fill-white opacity-80 hover:opacity-100'
        )}
      />
      {isVideo && (
        <WatchMoreModal
          videoId={item.youtubeVideoId}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}
    </div>
  )
}

export default function MobileGallery({ items }) {
  const sliderRef = useRef(null)
  const next = useCallback(() => sliderRef.current.slickNext())
  const prev = useCallback(() => sliderRef.current.slickPrev())

  return (
    <>
      <Slider
        ref={sliderRef}
        infinite
        autoplay
        autoplaySpeed={5000}
        speed={300}
        arrows={false}
        className="w-full self-center"
      >
        {sortBy(prop('sortOrder'), items).map((item, idx) => (
          <GalleryItem key={item.id} item={item} idx={idx} />
        ))}
      </Slider>
      <div className="container flex flex-row place-items-center justify-center gap-4 text-center">
        <CaretButton onClick={prev} svgClassName="fill-primary" />
        <CaretButton svgClassName="rotate-180 fill-primary" onClick={next} />
      </div>
    </>
  )
}
