import React from 'react'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

import useModal from '../../../hooks/useModal'
import { MainButtonLogo } from '../../shared/Buttons'
import { getImage } from '../../../utils/gatsby-strapi-helpers'
import RetailersModal from './RetailersModal'

export default function PhysicalPlatformButton({
  platform: { slug, label, shortLabel, logo },
  retailers,
}) {
  const { isModalOpen, closeModal, openModal } = useModal()
  return (
    <>
      <RetailersModal
        retailers={retailers}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
      <MainButtonLogo
        onClick={openModal}
        key={slug}
        alt={label}
        className={classNames(
          'flex h-[3.625rem] w-[12.25rem] items-center justify-center gap-2 !p-2 !text-label'
        )}
      >
        <GatsbyImage
          image={getImage(logo)}
          alt={label}
          className="h-[2.625rem] w-[48px]"
          objectFit="contain"
        />
        <span className="text-white">{shortLabel}</span>
      </MainButtonLogo>
    </>
  )
}
