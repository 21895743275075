import React from 'react'

export default function PlayButton({ onClick, svgClassName, btnClassName }) {
  const C = onClick ? 'button' : 'div'
  return (
    <C
      {...(onClick ? { onClick, type: 'button' } : {})}
      className={btnClassName}
    >
      <svg
        width="46"
        height="52"
        viewBox="0 0 46 52"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClassName}
      >
        <path d="M0.980772 -5.36442e-06L45.9808 25.9808L0.980771 51.9615L0.980772 -5.36442e-06Z" />
      </svg>
    </C>
  )
}
