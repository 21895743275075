import React from 'react'

import useStorePlatforms from '../../../hooks/useStorePlatforms'
import DigitalPlatformButton from './DigitalPlatformButton'
import { Media } from './constants'

export default function DigitalStore() {
  const digitalPlatforms = useStorePlatforms()

  return (
    <div className="flex flex-col flex-wrap justify-center gap-8 lg:flex-row">
      {digitalPlatforms
        .filter(platform =>
          platform.media.strapi_json_value.includes(Media.Digital)
        )
        .map(platform => (
          <DigitalPlatformButton key={platform.slug} platform={platform} />
        ))}
    </div>
  )
}
