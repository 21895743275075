import React from 'react'
import classNames from 'classnames'

export const Mobile = ({ className, children, ...rest }) => (
  <div className={classNames('block lg:hidden', className)} {...rest}>
    {children}
  </div>
)

export const Desktop = ({ className, children, ...rest }) => (
  <div className={classNames('hidden lg:block', className)} {...rest}>
    {children}
  </div>
)
