import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { prop, sortBy } from 'ramda'

import { allNodesForLocale } from '../../utils/gatsby-strapi-helpers'
import LocaleContext from '../../contexts/localContext'

const useBigFeatures = () => {
  const {
    allStrapiBigPictureSection: { nodes },
  } = useStaticQuery(graphql`
    query CoversQuery {
      allStrapiBigPictureSection {
        nodes {
          sortOrder
          title
          description
          showInMobile
          invertedLayout
          ctaLabel
          ctaUrl
          locale
          picture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)
  const bigFeatures = allNodesForLocale(locale)(nodes)
  return sortBy(prop('sortOrder'), bigFeatures)
}

export default useBigFeatures
