import classNames from 'classnames'
import React from 'react'

export default function Strip({ className }) {
  return (
    <div
      className={classNames(
        // watchout! if you add new breakpoint for h- you need to go and check all overrides and set the h- for that new breakpoint
        'h-[4.2rem] w-full bg-shuttles bg-contain bg-center bg-repeat-x lg:h-[10.5rem]',
        className
      )}
    />
  )
}
