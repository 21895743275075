import React, { useEffect } from 'react'

// Based on https://stackoverflow.com/a/71694411/4282993
const AutoPlayVideo = ({
  className,
  src,
  type = 'mp4',
  poster,
  onLoad = () => {},
}) => {
  useEffect(() => {
    const vid = document.getElementById('hero-video')
    vid.addEventListener('loadeddata', onLoad)
    vid.play().catch(() => {
      // it fails if src changes in between
    })
  }, [src])

  // Mobile browser need the `muted` prop to make `autoplay` to work
  // But also had to add `playsinline`. Tested on iPhone and Android
  const videoStr = `
        <video id="hero-video" autoplay loop muted playsinline class="w-full" ${
          poster ? `poster="${poster}"` : ''
        } >
          <source src=${src} type="video/${type}" />
        </video>
    `

  return (
    <div
      className={className}
      /* eslint react/no-danger: 0 */
      dangerouslySetInnerHTML={{ __html: videoStr }}
    />
  )
}

export default AutoPlayVideo
