import React from 'react'
import Modal from 'react-modal'
import InternetVideoPlayer from './InternetVideoPlayer'
import OverlayWithClose from '../shared/ModalCloseButton'

/**
 * A modal holding a youtube video for (almost) full-screen view
 *
 * @param { videoId } videoId is the youtube video id
 */
const WatchMoreModal = ({ videoId, isModalOpen, closeModal }) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    contentLabel="Star Trek Resurgence video"
    className="absolute inset-x-10 inset-y-5 top-12 overflow-auto rounded border-2 border-solid border-primary bg-black outline-none"
    overlayClassName="fixed inset-0 bg-black bg-opacity-90 z-40"
    overlayElement={OverlayWithClose}
  >
    <InternetVideoPlayer videoId={videoId} className="h-full w-full" />
  </Modal>
)

export default WatchMoreModal
