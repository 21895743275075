import { graphql, useStaticQuery } from 'gatsby'
import { prop, sortBy } from 'ramda'

const useStorePlatforms = () => {
  const {
    allStrapiStorePlatform: { nodes },
  } = useStaticQuery(graphql`
    query StorePlatformsQuery {
      allStrapiStorePlatform {
        nodes {
          slug
          label
          shortLabel
          sortOrder
          media {
            strapi_json_value
          }
          digitalStoreUrl
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  return sortBy(prop('sortOrder'), nodes)
}

export default useStorePlatforms
