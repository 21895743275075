import React, { useCallback, useRef } from 'react'
import { prop, sortBy } from 'ramda'

import useModal from '../../../../hooks/useModal'
import DesktopGalleryItem from './DesktopGalleryItem'
import DesktopMediaModal from './DesktopMediaModal'

export default function DesktopGallery({ items }) {
  const { isModalOpen, closeModal, openModal } = useModal()
  const modalRef = useRef(null)

  const openGalleryAtItem = useCallback(
    idx => {
      modalRef.current.selectItem(idx)
      openModal()
    },
    [modalRef, openModal]
  )

  return (
    <>
      <div className="flex flex-wrap justify-center gap-4">
        {sortBy(prop('sortOrder'), items).map((item, idx) => (
          <DesktopGalleryItem
            key={item.id}
            item={item}
            idx={idx}
            onClick={openGalleryAtItem}
            className=" border-[1px] border-transparent hover:border-primary"
          />
        ))}
      </div>
      <DesktopMediaModal
        items={items}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        ref={modalRef}
      />
    </>
  )
}
