import { prop, sortBy } from 'ramda'
import React, { useCallback, useRef } from 'react'
import Slider from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'

import Quote from './Quote'
import { allNodesForLocale } from '../../../utils/gatsby-strapi-helpers'
import LocaleContext from '../../../contexts/localContext'
import CaretButton from '../../shared/CaretButton'
import { SpecialHeader3 } from '../../shared/Text'
import useMedia from '../../../hooks/useMedia'
import useMessages from '../../../hooks/useMessages'

export default function Accolades() {
  const sliderRef = useRef(null)
  const next = useCallback(() => sliderRef.current.slickNext())
  const prev = useCallback(() => sliderRef.current.slickPrev())
  const msg = useMessages()

  const {
    allStrapiAccolade: { nodes },
  } = useStaticQuery(graphql`
    query AccoladeQuery {
      allStrapiAccolade {
        nodes {
          id
          sortOrder
          quote
          locale
          url
          picture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          subscriberLogo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)
  const accolades = allNodesForLocale(locale)(nodes)
  const isMediumRes = useMedia('(min-width: 1024px)')
  return (
    <div className="lg:container lg:py-32">
      <SpecialHeader3 className="text-center">
        {msg('accolades.title')}
      </SpecialHeader3>

      <div className="flex flex-col gap-8 lg:flex-row lg:justify-center lg:gap-8 xl:gap-0">
        <CaretButton
          btnClassName="hidden lg:flex w-[10%] 2xl:w-[20%] justify-end items-center"
          svgClassName="fill-cyan"
          svgProps={{ width: 30, height: 48 }}
          onClick={prev}
        />
        <Slider
          ref={sliderRef}
          infinite
          autoplay
          autoplaySpeed={5000}
          speed={300}
          arrows={false}
          swipe={!isMediumRes}
          className="w-full self-center lg:w-[90%]"
        >
          {sortBy(prop('sortOrder'), accolades).map((accolade, idx) => (
            <Quote key={accolade.id} accolade={accolade} idx={idx} />
          ))}
        </Slider>
        <CaretButton
          btnClassName="hidden lg:flex w-[10%] 2xl:w-[20%] justify-start items-center"
          svgClassName="rotate-180 fill-cyan"
          svgProps={{ width: 30, height: 48 }}
          onClick={next}
        />
        {/* <div className="container flex flex-row place-items-center justify-center gap-4 text-center lg:hidden">
          <CaretButton onClick={prev} svgClassName="fill-cyan" />
          <CaretButton svgClassName="rotate-180 fill-cyan" onClick={next} />
        </div> */}
      </div>
    </div>
  )
}
