import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { prop, sortBy } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import LocaleContext from '../../contexts/localContext'
import {
  allNodesForLocale,
  getAltText,
  getImage,
} from '../../utils/gatsby-strapi-helpers'

import { Caption, Subtitle } from '../shared/Text'

const Features = () => {
  const {
    allStrapiFeature: { nodes },
  } = useStaticQuery(graphql`
    query FeaturesQuery {
      allStrapiFeature {
        nodes {
          id
          locale
          title
          description
          sortOrder
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)

  const features = allNodesForLocale(locale)(nodes)

  return (
    <div className="container flex flex-wrap justify-center gap-8 py-24">
      {sortBy(prop('sortOrder'), features).map(
        ({ id, title, description, cover }) => (
          <div key={id} className="flex flex-col gap-8 lg:w-[30%]">
            <GatsbyImage
              image={getImage(cover)}
              alt={getAltText(cover)}
              className="h-auto w-full rounded"
              layout="fullWidth"
            />

            <div className="flex flex-col gap-4">
              <Subtitle className="font-normal">{title}</Subtitle>

              <Caption>{description}</Caption>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default Features
