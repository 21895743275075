import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getAltText, getImage } from '../../../utils/gatsby-strapi-helpers'
import { Subtitle } from '../../shared/Text'

export default function Quote({ accolade, idx }) {
  const { quote, picture, subscriberLogo, url } = accolade
  return (
    <div className="pt-14 max-lg:container lg:px-4">
      <div className="flex flex-col place-items-center justify-center gap-8 text-center lg:flex-row lg:gap-12">
        <div className="rounded lg:w-full lg:max-w-[47.5%] lg:border-2 lg:border-cyan lg:py-2">
          <GatsbyImage
            image={getImage(picture)}
            alt={getAltText(picture)}
            className="rounded lg:w-[99%]"
            objectFit="contain"
            loading={idx === 0 || idx === 1 ? 'eager' : 'lazy'}
          />
        </div>
        <div className="flex flex-col items-center gap-8 lg:w-80">
          <Subtitle>{`"${quote}"`}</Subtitle>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <GatsbyImage
              image={getImage(subscriberLogo)}
              alt={getAltText(subscriberLogo)}
              className="w-24"
              loading={idx === 0 || idx === 1 ? 'eager' : 'lazy'}
            />
          </a>
        </div>
      </div>
    </div>
  )
}
