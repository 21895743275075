import React, { useState } from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

import { MainButtonCompact, MainButtonWide } from './shared/Buttons'
import { getImage } from '../utils/gatsby-strapi-helpers'
import usePlatforms from '../hooks/usePlatforms'
import useCampaignPicture from '../hooks/campaigns/useCampaignPicture'

import useMessages from '../hooks/useMessages'
import { Desktop, Mobile } from './viewport/breakpoints'
import AutoPlayVideo from './AutoPlayVideo'
import HeroVideo from '../../static/videos/Header_video_stars.mp4'
import MobileHeroVideo from '../../static/videos/Mobile_header.mp4'
import HeroPoster from '../../static/videos/Hero_poster.webp'
import HeroPosterMobile from '../../static/videos/Hero_mobile_poster.webp'
import useMedia from '../hooks/useMedia'
import WatchMoreModal from './video/WatchMoreModal'
import useModal from '../hooks/useModal'
import LocalizedLink from './localizedLink'

const MOBILE_VIDEO_HEIGHT = 1248
const MOBILE_VIDEO_WIDTH = 780

const PlatformLogos = ({ setHover, hover, alwaysSolid }) => {
  const { platforms } = usePlatforms()
  return (
    <div
      className={classNames(
        'flex w-[19.75rem] flex-wrap items-center justify-center gap-4 transition-opacity duration-300',
        {
          'opacity-70': !hover && !alwaysSolid,
          'opacity-100': hover || alwaysSolid,
        }
      )}
      onMouseEnter={() => setHover?.(true)}
      onMouseLeave={() => setHover?.(false)}
    >
      {platforms.map(({ slug, name, iconSmall }) => (
        <GatsbyImage
          key={slug}
          image={getImage(iconSmall)}
          alt={name}
          className="w-[2.5rem] lg:w-[3rem]"
          objectFit="contain"
        />
      ))}
    </div>
  )
}

const HeroButton = ({ text, href, ...rest }) => (
  <MainButtonCompact type="button" {...rest}>
    <LocalizedLink to={href}>
      <span className="text-heroButton font-normal">{text}</span>
    </LocalizedLink>
  </MainButtonCompact>
)

const HeroMobileButton = ({ text, ...rest }) => (
  <MainButtonWide {...rest} className="h-[2.5rem] w-full !min-w-0">
    <span className="text-heroButton font-normal">{text}</span>
  </MainButtonWide>
)

const MainLogo = ({ className }) => {
  const campaignPicture = useCampaignPicture('mainLogo')

  return campaignPicture ? (
    <GatsbyImage
      image={getImage(campaignPicture)}
      alt="Resurgence Logo"
      className={className}
    />
  ) : (
    <StaticImage
      src="../../static/pics/Main/ResurgenceLogo_sm.png"
      alt="Resurgence Logo"
      className={className}
    />
  )
}

const Video = () => {
  const isDesktop = useMedia('(min-width: 1024px)')

  const video = isDesktop !== undefined && (
    <figure className="min-w-full">
      <AutoPlayVideo
        src={isDesktop ? HeroVideo : MobileHeroVideo}
        poster={isDesktop ? HeroPoster : HeroPosterMobile}
      />
    </figure>
  )

  return (
    <>
      <Mobile
        className="w-full"
        style={{
          height: `calc(100vw * ${MOBILE_VIDEO_HEIGHT / MOBILE_VIDEO_WIDTH})`,
          maxHeight: MOBILE_VIDEO_HEIGHT,
        }}
      >
        {video}
      </Mobile>
      <Desktop className="h-full w-full">{video}</Desktop>
    </>
  )
}

function WatchTrailerButton({ text, mobile }) {
  const { isModalOpen, closeModal, openModal } = useModal()

  const ButtonCompo = mobile ? HeroMobileButton : HeroButton
  return (
    <>
      <WatchMoreModal
        videoId="_SaGlhUXHNc"
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
      <ButtonCompo href="#" text={text} onClick={openModal} />
    </>
  )
}

const DesktopVersion = ({ msg }) => {
  const [hoverBuyButton, setHoverBuyButton] = useState()
  return (
    <>
      <div className="container absolute top-0 left-0 right-0 z-10 hidden h-full w-full flex-col justify-end lg:flex lg:justify-center">
        <header className="flex w-full flex-col items-center justify-center gap-8 pb-12 lg:w-[26rem] lg:pt-24">
          <MainLogo className="w-full" />

          <div className="flex w-full flex-col items-center gap-6">
            <div className="flex flex-col items-center gap-4">
              <HeroButton href="/#get-game" text={msg('buy-now')} />
            </div>
            <div className="flex flex-col items-center gap-4">
              <WatchTrailerButton text={msg('watch-trailer')} />
            </div>
            <div className="flex flex-col items-center gap-4">
              <HeroButton href="/sto-pc-offer" text={msg('sto-promo-offer')} />
            </div>
            <PlatformLogos
              setHover={setHoverBuyButton}
              hover={hoverBuyButton}
            />
          </div>
        </header>
      </div>

      <div className="container absolute top-0 left-0 right-0 hidden h-full w-full flex-col items-end justify-start lg:flex lg:justify-end">
        <StaticImage
          src="../../static/pics/Main/Characters.png"
          alt="characters"
          className="mt-20 lg:mt-0 lg:w-[65%]"
        />
      </div>
    </>
  )
}

const MobileVersion = ({ msg }) => (
  <div className="container absolute top-0 left-0 right-0 z-10 flex h-full w-full flex-col items-center justify-center gap-8 lg:hidden">
    <div className="relative max-w-[500px]">
      <StaticImage
        src="../../static/pics/Main/CharactersMobile.png"
        alt="characters"
      />
      <MainLogo className="!absolute bottom-0 left-0 w-full" />
    </div>

    <div className="flex w-full flex-col items-center justify-center gap-8 pb-12 lg:w-[26rem] lg:pt-24">
      <div className="flex w-[214px] flex-col items-center gap-4">
        <WatchTrailerButton text={msg('watch-trailer')} mobile />
        <HeroMobileButton href="/#get-game" text={msg('buy-now')} />
        <HeroMobileButton href="/sto-pc-offer" text={msg('sto-promo-offer')} />
        <div className="mt-2">
          <PlatformLogos alwaysSolid />
        </div>
      </div>
    </div>
  </div>
)

const Hero = () => {
  const msg = useMessages()

  return (
    <div
      id="hero"
      className="relative z-0 h-auto min-h-[722px] w-full overflow-hidden sm:h-[100vh] lg:h-screen lg:max-h-[55vw] lg:min-h-0"
    >
      <Video />

      <MobileVersion msg={msg} />
      <DesktopVersion msg={msg} />

      <div className="absolute bottom-0 h-32 w-full  bg-gradient-to-t from-background to-transparent translate-z-0" />
    </div>
  )
}
export default Hero
