import React from 'react'
import { filter } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Media from './Media'
import isFeatured from './isFeatured'

export default function FeaturedGallerySection() {
  const {
    allStrapiGalleryItem: { nodes: items },
  } = useStaticQuery(graphql`
    query FeaturedGalleryItemsQuery {
      allStrapiGalleryItem {
        nodes {
          id
          sortOrder
          youtubeVideoId
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Media items={filter(isFeatured, items)} titleKey="featured-media.title" />
  )
}
