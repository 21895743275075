import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState, useCallback, useEffect } from 'react'
import { getAltText, getImage } from '../../../../utils/gatsby-strapi-helpers'
import PlayButton from '../../../shared/PlayButton'
import InternetVideoPlayer from '../../../video/InternetVideoPlayer'

export default function DesktopGalleryItem({
  item,
  idx,
  onClick,
  className,
  selected,
}) {
  const isVideo = item.youtubeVideoId

  const [clickedPlay, setClickedPlay] = useState(false)
  const togglePlay = useCallback(() => {
    setClickedPlay(true)
  }, [setClickedPlay])

  useEffect(() => {
    // stop video!
    if (isVideo && selected !== idx && clickedPlay) setClickedPlay(false)
  }, [selected, clickedPlay])

  const openItem = useCallback(() => {
    if (onClick) {
      // then I need to open modal to play, so I call the onClick callback
      onClick(idx)
    } else if (isVideo) {
      // will play inplace, switch to playing mode
      togglePlay()
    }
  }, [onClick, togglePlay, idx])

  // TODO: maybe a perfo impro could be to manage that state and the inet vid player inside a subcompo

  const C = isVideo || onClick ? 'button' : 'div'
  const buttonProps = { type: 'button', onClick: openItem }
  return (
    <div
      className={classNames(
        'rounded-4 relative flex w-[21.87vw] justify-center',
        className,
        {
          relative: isVideo,
        }
      )}
    >
      {!clickedPlay && (
        <C
          {...(isVideo || onClick ? buttonProps : {})}
          className="my-auto focus:outline-none focus:ring-1 focus:ring-primary/50 focus:ring-offset-0 active:outline-none"
        >
          <GatsbyImage
            style={{ borderRadius: '0.25rem' }}
            image={getImage(item.picture)}
            alt={getAltText(item.picture)}
            objectFit="contain"
            loading={idx === 0 || idx === 1 ? 'eager' : 'lazy'}
          />
          <PlayButton
            btnClassName={classNames(
              { hidden: !isVideo },
              'absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 fill-white opacity-80 hover:opacity-100'
            )}
          />
        </C>
      )}
      {isVideo && clickedPlay && (
        <InternetVideoPlayer
          opts={{ playerVars: { autoplay: 0 } }}
          videoId={item.youtubeVideoId}
          className="h-full w-full"
        />
      )}
    </div>
  )
}
