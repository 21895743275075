import React, { useMemo, useState } from 'react'
import { identity, prop, sortBy } from 'ramda'
import classNames from 'classnames'

import Arrow from './Arrow.png'

const Select = ({ options, labelFn = identity, onSelected, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue)
  const [open, setOpen] = useState(false)

  const select = opt => () => {
    setSelected(opt)
    setOpen(false)
    onSelected(opt)
  }

  const sortedOptions = useMemo(() => sortBy(prop('label'), options), options)
  return (
    <div className={classNames(`w-[18rem]`, 'relative text-black')}>
      <div
        type="button"
        className="
                  flex
                  h-[2.5rem]
                  w-full
                  cursor-pointer
                  items-center
                  justify-between
                  rounded-md

                  border-2 border-primary

                  bg-white
                  p-[1rem]
                  "
        onClick={() => setOpen(!open)}
      >
        <span className="sBtn-text">{labelFn(selected) ?? defaultValue}</span>
        <img src={Arrow} alt="open dropdown" />
      </div>

      {open && (
        <ul
          className={classNames(
            `max-h-[18rem]`,
            'absolute left-0 z-20 mt-[10px] w-full !overflow-y-scroll rounded-md bg-white p-[1rem]'
          )}
        >
          {sortedOptions.map(opt => (
            <li
              key={opt.slug}
              className="
                        option-text
                        flex
                        h-[2.5rem]
                        cursor-pointer
                        items-center
                        rounded-md
                        bg-white
                        py-[1rem] px-[4px]
                        hover:bg-[#F2F2F2]
                        "
              onClick={select(opt)}
            >
              {labelFn(opt)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Select
