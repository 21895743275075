import { mergeDeepRight } from 'ramda'
import React from 'react'
import YouTube from 'react-youtube'

const defaultOpts = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 1,
    rel: 0,
  },
}

const InternetVideoPlayer = ({ videoId, opts = {}, ...props }) => (
  <div {...props}>
    <YouTube
      className="h-full w-full"
      opts={mergeDeepRight(defaultOpts, opts)}
      videoId={videoId}
      onReady={evt => evt.target.playVideo()}
    />
  </div>
)
export default InternetVideoPlayer
