import React from 'react'
import { reject } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Media from './Media'
import isFeatured from './isFeatured'

export default function GallerySection() {
  const {
    allStrapiGalleryItem: { nodes: items },
  } = useStaticQuery(graphql`
    query GalleryItemsQuery {
      allStrapiGalleryItem {
        nodes {
          id
          sortOrder
          youtubeVideoId
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  return <Media items={reject(isFeatured, items)} titleKey="media.title" />
}
