import React, {
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import Modal from 'react-modal'
import Slider from 'react-slick'
import { prop, sortBy } from 'ramda'

import OverlayWithClose from '../../../shared/ModalCloseButton'
import CaretButton from '../../../shared/CaretButton'
import DesktopGalleryItem from './DesktopGalleryItem'

function _DesktopMediaModal({ items, isModalOpen, closeModal }, ref) {
  const sliderRef = useRef(null)
  const [selected, setSelectedItem] = useState(0)

  useImperativeHandle(ref, () => ({
    selectItem: idx => {
      // TODO: check how to avoid the timeout... it always opens the modal at position 0
      setTimeout(() => {
        if (sliderRef.current) sliderRef.current.slickGoTo(idx, false)
      }, 5)
    },
  }))
  const next = useCallback(() => sliderRef.current.slickNext(), [sliderRef])
  const prev = useCallback(() => sliderRef.current.slickPrev(), [sliderRef])
  const onChange = useCallback(
    (older, newer) => {
      if (older !== newer) setSelectedItem(newer)
    },
    [setSelectedItem]
  )
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Star Trek Resurgence video"
      className="absolute inset-x-10 inset-y-5 top-12 overflow-auto rounded bg-black outline-none 2xl:inset-x-32 2xl:inset-y-20"
      overlayClassName="fixed inset-0 bg-black bg-opacity-90 z-40"
      overlayElement={OverlayWithClose}
    >
      <div className="flex h-full flex-row justify-center py-2">
        <CaretButton
          btnClassName="hidden mr-4 lg:flex w-[10%] 2xl:w-[20%] justify-end items-center"
          svgClassName="fill-primary"
          onClick={prev}
        />
        <Slider
          ref={sliderRef}
          infinite
          speed={300}
          arrows={false}
          className="w-[82%] self-center"
          beforeChange={onChange}
        >
          {sortBy(prop('sortOrder'), items).map((item, idx) => (
            <DesktopGalleryItem
              key={item.id}
              item={item}
              idx={idx}
              selected={selected}
              className="mx-auto my-auto !h-[80vh] !w-[80%]"
            />
          ))}
        </Slider>
        <CaretButton
          btnClassName="hidden ml-4 lg:flex w-[10%] 2xl:w-[20%] justify-start items-center"
          svgClassName="rotate-180 fill-primary"
          onClick={next}
        />
      </div>
    </Modal>
  )
}
const DesktopMediaModal = forwardRef(_DesktopMediaModal)
DesktopMediaModal.displayName = 'DesktopMediaModal'
export default DesktopMediaModal
