import React from 'react'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

import { MainButtonLogo } from '../../shared/Buttons'
import { getImage } from '../../../utils/gatsby-strapi-helpers'

export default function DigitalPlatformButton({
  platform: { slug, label, shortLabel, logo, digitalStoreUrl },
}) {
  return (
    <MainButtonLogo
      href={digitalStoreUrl}
      target="_blank"
      rel="noopener noreferrer"
      key={slug}
      alt={label}
      className={classNames(
        'flex h-[3.625rem] w-[12.25rem] basis-[20%] items-center justify-center gap-2 !p-2 !text-label'
      )}
    >
      <GatsbyImage
        image={getImage(logo)}
        alt={label}
        className="h-[2.625rem] w-[48px]"
        objectFit="contain"
      />
      <span className="text-white">{shortLabel}</span>
    </MainButtonLogo>
  )
}
